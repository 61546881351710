import React from "react"
import Layout from "../components/Layout/layout"
import Header from "../components/Header/header"
import Footer from "../components/Footer/footer"
import Section from "../components/Section/section"

export default function PageNotFound() {
  return <Layout><Header lang="en" slug="404"/>
    <main id="main">
      <Section title='404 Page not found!'>
        The page you requested does not exist. Please try other pages.
    </Section>
    </main>
    <Footer /></Layout>
}